import { MenuRounded } from "@material-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../../../images/TestAssessify.png";
import { useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";
import toast from "react-hot-toast";

const AdminHeader = ({ siebarToggle, windowWidth, refreshData }) => {
    const { value } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation()
    
    const handleLogout = async () => {
      toast.loading("Logging you out...");
      try {
        const data = await api.logout();
        toast.remove();
        toast.success(data?.message);
        navigate("/");
        sessionStorage.clear();
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    };

  return (
    <div className="w-full h-[60px] bg-white sticky top-0 z-20 shadow-md">
      <div className="h-full px-5 py-0 flex items-center justify-between">
        {windowWidth < 768 && (
          <button onClick={siebarToggle} className="p-2">
            <MenuRounded />
          </button>
        )}
        <div className="h-full">
          <img
            src={Logo}
            alt="Test Assessify"
            className="w-[180px] h-full object-contain"
          />
        </div>
        <div className="flex items-center">
          { location.pathname.includes('/affiliate/') ?
            <button
              onClick={handleLogout}
              className="border border text-white bg-customOrange border-customOrange block w-full px-16 py-3 rounded-full  capitalize text-sm"
            >
                log out
            </button>
          :
          <div className="flex mr-4 items-center">
            <img
              src="/avatar.jpg"
              className="w-[25px] h-[25px] rounded-full"
              alt="user"
            />
            <span className="ml-2 text-baseFont">
              {JSON.parse(sessionStorage.getItem("user"))?.name}
            </span>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
