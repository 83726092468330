import "./App.css";
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-one-expression-per-line */
import "tw-elements";
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Signin from "./pages/Signin";
import toast, { Toaster } from "react-hot-toast";
import { UserContext } from "./context/UserContext";
import { CustomToastProvider } from "./components/customtoast/CustomToastProvider";

/*Pages */
import Admin from "./pages/admin/Admin";
import Register from "./pages/Register";
import ExamMonitorRoutes from "./pages/exam-monitor/ExamMonitorRoutes";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import VerifyAccount from "./pages/VerifyAccount";

import ErrorBoundary from "./pages/admin/components/errorBoundary/errorBoundary";
import Newsletter from "./pages/admin/pages/Newsletter/Newsletter";
import AffiliateRoutes from "./pages/affiliate/pages/AffiliateRoutes";
import SignUp from "./pages/SignUp/SignUp";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const [value, setValue] = useState({ projects: [], user: {} });
  const location = useLocation();
  const currentRoute = location.pathname;
  const isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"));
  const [inactiveTime, setInactiveTime] = useState(0);

  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    const currentRouteIsAdmin = currentRoute.includes("admin");

    if (userData) {
      const user = JSON.parse(userData);
      setValue((x) => ({ ...x, user }));
    } else if (!userData && currentRouteIsAdmin) {
      navigate("/");
      toast.remove();
      toast("We have signed you out!");
    }

    const handleUserActivity = () => {
      setInactiveTime(0);
    };

    const interval = setInterval(() => {
      setInactiveTime((prevTime) => prevTime + 10);
    }, 10000);

    const autoLogout = () => {
      if (isLoggedIn === false || inactiveTime > 900) {
        currentRouteIsAdmin && toast.success("You have been logged out");
        navigate("/");
        sessionStorage.clear();
      }
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
      clearInterval(interval);
      autoLogout();
    };
  }, [inactiveTime, isLoggedIn, currentRoute]);

  //prettier-ignore
  return (
    <>
      <ErrorBoundary>
        <CustomToastProvider>
          <QueryClientProvider client={queryClient}>
            <UserContext.Provider value={{ value, setValue }}>
              <Routes>
                <Route path="/" element={<Signin />} /> 
                {/*<Route path="/" element={<Newsletter />} />*/}
                <Route path="/admin/*" element={<Admin />} />
                <Route path="/affiliate/*" element={<AffiliateRoutes />} />
                <Route path="/register" element={<SignUp />} />
                <Route path="/exam-monitor/*" element={<ExamMonitorRoutes />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/email/verify/:id1/:id2"element={<VerifyAccount />} />
              </Routes>
            </UserContext.Provider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
          </QueryClientProvider>
          {/* <ChatWoot /> */}
          <Toaster />
        </CustomToastProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
